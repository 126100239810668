<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
    <BRow>

      <BCol cols="12" md="12">

        <BCard header-tag="header" footer-tag="footer">

          <div slot="header">
            <BIconPersonLinesFill/> <strong> 사용자 로그인 이력 </strong>


            <div class="card-header-actions">
              <small class="text-muted">로그인 기록 및 사용자 정보 변경 이력을 조회합니다.</small>
            </div>
          </div>

          <BRow class="mb-2">
            <BCol>
              <BInputGroup prepend="검색"  size="sm">
                <BFormSelect v-model="searchField"
                             style="background-color:#2f303a"
                             size="sm"
                             :options="searchOpts"/>
                <BInputGroupAppend>
                <BFormInput v-model="searchWord" size="sm" v-on:keyup.enter="getUserHistList"/>
                </BInputGroupAppend>
              </BInputGroup>
            </BCol>

            <BCol>
              <BInputGroupAppend>
                <BFormInput v-model="fromDate" type="date" size = "sm" @input="setFromDate" @keyup.enter="getUserHistList"/>
                <BFormInput v-model="toDate" type="date" size="sm" @input="setToDate" @keyup.enter="getUserHistList"/>
              </BInputGroupAppend>
            </BCol>
            <BCol></BCol>
          </BRow>

          <!-- User Table -->
          <BTable small sticky-header="500px" selectable hover responsive bordered dark
                  class="text-nowrap small"
                  thead-class="text-center"
                  ref="userTable"
                  id="userTable"
                  select-mode="single"
                  selectedVariant="success"
                  :per-page="userPerPage"
                  :current-page="userCurrPage"
                  :items="userItems"
                  :fields="userFields"
                  @row-selected="userRowSelected">
          </BTable>

          <BRow>
            <BCol>
              <BPagination
                size="sm"
                v-model="userCurrPage"
                :total-rows="userRows"
                :per-page="userPerPage"
                aria-controls="userTable"
              ></BPagination>
            </BCol>

          </BRow>


        </BCard>

      </BCol>
    </BRow>
  </div>

</template>



<script>
//-------------------------------------------------------------------------------------------------
// import moment from 'moment';
import {
    apiCall,
} from '../../common/utils';
import qs from 'querystring';
import moment from "moment";

const roleMap =  {
    AP: '가맹점대표',
    AA: '출동기사',
    AS: '가맹점스텝',
    CD: '센터관리자',
    CT: '상담사',
    CS: '센터스텝',
    OA: '관리자',
    OM: '담당자',
    OS: '스텝',
};

//----------------------------------------------------------------------------------------------------
export default {
    name: 'userLogin',
    data () {
        return {
          user: null,
          isUserSelected: false,
          isUserFormShow: false,

          userItems: [],
          userPerPage: 20,
          userCurrPage: 1,
          userFields: [
              { key:'createdAt'   , sortable: true, label: '일시', formatter: v=>{return (v? moment(v).format('YYYY-MM-DD HH:mm:ss'):'')} },
              { key:'userId'      , sortable: true, label: '아이디'},
              { key:'role'        , sortable: true, label: '권한', formatter: v=>{return (v? roleMap[v]:'')} },
              { key:'isLoggedIn'  , sortable: true, label: '로그인 여부'},
              { key:'failCount'   , sortable: true, label: '로그인 실패 횟수'},
              // { key:'tokenId'     , sortable: true, label: '토큰 아이디'},
              { key:'ipAddr'      , sortable: true, label: '접속 IP'},
              { key:'result'      , sortable: true, label: '로그인 결과'},
              { key:'isInitPwd'   , sortable: true, label: '비밀번호 초기화'},
              { key:'isLocked'    , sortable: true, label: '계정 잠김'},
              { key:'lockedAt'    , sortable: true, label: '잠금 시각', formatter: v=>{return (v?moment(v).format('YYYY-MM-DD HH:mm:ss'):'')} },
              { key:'reasonLock'  , sortable: true, label: '잠금 사유'},
              // { key:'pwdExpiresAt', sortable: true, label: '비밀번호 만료일시', formatter: v=>{return (v? moment(v).format('YYYY-MM-DD HH:mm:ss'):'')} },
              { key:'device'      , sortable: true, label: '접속 디바이스 정보'},
          ],
          fromDate: moment(this.fromDate).add(-1,"month").format("YYYY-MM-DD"),
          toDate: moment().format("YYYY-MM-DD"),
          searchOpts: [
              {value:'userId', text:'사용자 아이디'},
              {value:'result', text:'로그인 결과'},
          ],
          searchWord: '',
          searchField: 'userId',
          isLastLimit: 'N'
        }
    },

async created(){
    try{
        await this.getUserHistList();
    }catch(err){
        console.log(err);
    }
},

computed: {
    userRows(){
        return this.userItems.length;
    }
},

methods: {
    setFromDate(){
        this.toDate =  moment(this.fromDate).add(1,"month").subtract(1,'day').format("YYYY-MM-DD");
    },

    setToDate(){
        if( this.toDate < this.fromDate) this.fromDate = this.toDate
        //this.fromDate =  moment(this.toDate).startOf("month").format("YYYY-MM-DD");
    },

    async getUserHistList(){

        let param = {};

        try{

            if( this.searchWord ){
                param[this.searchField] = this.searchWord;
            }

            param['toDate'] = this.toDate;
            param['fromDate'] = this.fromDate;

            const {result} = await apiCall('get', `/api/user/history/query?${qs.stringify(param)}`);
            this.userItems = result

        }catch(err){
            console.log( err );
        }
    },


    userRowSelected(item){
        console.log("------------ userRowSelected ------------");
        if( item.length === 0 ) {
            this.user = null;
            this.actionMode = "post";
            this.isUserSelected = false;
            this.isUserFormShow = false;
            this.rePassword = '';
            this.newPassword = '';
            this.originPassword = '';
        }else{
            this.user = item[0];
            this.rePassword = '';
            this.newPassword = '';
            this.originPassword = '';
            this.user.newPassword = '';
            this.user.rePassword = '';
            this.actionMode = "put";
            this.isUserSelected = true;
            console.log( "********** user----------------->", JSON.stringify(item[0]) );
        }
    },

}
}
</script>
